import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { TabLinks } from '../../../components/TabLinks';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "visualizacao-de-dados",
      "style": {
        "position": "relative"
      }
    }}>{`Visualização de dados`}</h1>
    <p>{`A visualização de dados consiste em apresentar graficamente informações e dados para sintetizar informações complexas e compreender padrões e exceções apresentados nos dados. A visualização de dados não deve ser usada como decoração.`}</p>
    <TabLinks items={[{
      title: 'Modelos',
      href: '/design-guidelines/data-visualization/models'
    }, {
      title: 'Uso',
      href: '/design-guidelines/data-visualization/usage'
    }]} mdxType="TabLinks" />
    <h2 {...{
      "id": "qual-grafico-usar",
      "style": {
        "position": "relative"
      }
    }}>{`Qual gráfico usar`}</h2>
    <p>{`O tipo de gráfico que você usa depende principalmente de duas coisas: os dados que você deseja comunicar e o que deseja transmitir sobre esses dados. Essas diretrizes fornecem descrições de vários tipos diferentes de gráficos e seus casos de uso.`}</p>
    <h3 {...{
      "id": "comparacao",
      "style": {
        "position": "relative"
      }
    }}>{`Comparação`}</h3>
    <p>{`Compare dados entre várias categorias distintas.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/bar.png",
        "alt": "Bar chart"
      }}></img>{` `}<img parentName="p" {...{
        "src": "/image/stacked-bar.png",
        "alt": "Stacked bar chart"
      }}></img>{` `}<img parentName="p" {...{
        "src": "/image/grouped-bar.png",
        "alt": "Grouped bar chart"
      }}></img>{` `}<img parentName="p" {...{
        "src": "/image/bubble-plot.png",
        "alt": "Bubble plot"
      }}></img>{` `}<img parentName="p" {...{
        "src": "/image/line.png",
        "alt": "Line chart"
      }}></img></p>
    <h3 {...{
      "id": "mudanca-ao-longo-do-tempo",
      "style": {
        "position": "relative"
      }
    }}>{`Mudança ao longo do tempo`}</h3>
    <p>{`Utilize para apresentar dados durante um período de tempo, como tendências ou comparações entre diferentes categorias.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/bar.png",
        "alt": "Bar chart"
      }}></img>{` `}<img parentName="p" {...{
        "src": "/image/stacked-bar.png",
        "alt": "Stacked bar chart"
      }}></img>{` `}<img parentName="p" {...{
        "src": "/image/area.png",
        "alt": "Area chart"
      }}></img>{` `}<img parentName="p" {...{
        "src": "/image/line.png",
        "alt": "Line chart"
      }}></img></p>
    <h3 {...{
      "id": "relacao--distribuicao",
      "style": {
        "position": "relative"
      }
    }}>{`Relação & Distribuição`}</h3>
    <p>{`Mostre o relacionamento entre duas ou mais variáveis ou a frequência com que os dados ocorrem.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/bubble-plot.png",
        "alt": "Bubble plot"
      }}></img>{` `}<img parentName="p" {...{
        "src": "/image/scatter-plot.png",
        "alt": "Scatter plot"
      }}></img></p>
    <h3 {...{
      "id": "controle",
      "style": {
        "position": "relative"
      }
    }}>{`Controle`}</h3>
    <p>{`Use em processos para acompanhar a evolução de uma variável em relação a uma ou várias variáveis de referência.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/line-control.png",
        "alt": "Line control chart"
      }}></img>{` `}<img parentName="p" {...{
        "src": "/image/area-control.png",
        "alt": "Area control chart"
      }}></img></p>
    <h3 {...{
      "id": "parte-para-o-todo",
      "style": {
        "position": "relative"
      }
    }}>{`Parte para o todo`}</h3>
    <p>{`Mostrar como elementos parciais somam um total.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/pie.png",
        "alt": "Pie chart"
      }}></img>{` `}<img parentName="p" {...{
        "src": "/image/stacked-bar.png",
        "alt": "Stacked bar chart"
      }}></img>{` `}<img parentName="p" {...{
        "src": "/image/stacked-area.png",
        "alt": "Stacked area chart"
      }}></img></p>
    <h2 {...{
      "id": "dashboards",
      "style": {
        "position": "relative"
      }
    }}>{`Dashboards`}</h2>
    <h3 {...{
      "id": "dashboard-operacional",
      "style": {
        "position": "relative"
      }
    }}>{`Dashboard operacional`}</h3>
    <p>{`Use para fornecer informações que demandam ação imediata do usuário e apresentar dados sensíveis ao tempo, como monitoramento de dados. Esse tipo de dashboard é atualizada constantemente.`}</p>
    <h3 {...{
      "id": "dashboard-analitica",
      "style": {
        "position": "relative"
      }
    }}>{`Dashboard analítica`}</h3>
    <p>{`Use a Dashboard analítica para ajudar os usuários a tomar ações futuras, investigar ou analisar dados. É atualizada com menos frequência que a Dashboard operacional (ex.: 1 vez ao dia). Saiba mais sobre os tipos de Dashboards no `}<a parentName="p" {...{
        "href": "https://www.nngroup.com/articles/dashboards-preattentive/"
      }}>{`website Nielsen and Norman group`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      